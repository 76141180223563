import logo from './img/bare-logo.png'
import esska from './img/esska.png'
import imse from './img/imse.png'
import vimse from './img/vimse.png'
import './App.scss'

function App() {
  var bgclass = Math.floor(Math.random() * Math.floor(5))
  console.log(bgclass)
  const appClass = `App bg-${bgclass}`

  return (
    <div className={appClass}>
      <section className="Content">
        <header>
          <a href="https://pivot.se">pivot.se</a>
        </header>
        <main>
          <img src={logo} className="Logo" alt="logo" />
          <p className="Preamble">
            Reduce, Reuse, Repair & Return
          </p>
          <p><strong>Bare Collective</strong> är en företagsgrupp inom barn- och hygienprodukter, som är övertygade om att det sunda och goda också har en kommersiell kraft. Med visionen att vara med och göra hållbar konsumtion till normen, inte undantaget, utvecklar vi företag som möjliggör beteendeförändring. Tillsammans med eldsjälarna bakom våra varumärken erbjuder vi kvalitetsprodukter som blir en naturlig del av livet, genom livet. Vi har en innovativ företagskultur och ser oss som talespersoner för en cirkulär framåtrörelse. Rebels with a cause.</p>
        </main>
        <div className="Contact">
          <p>Kontakt: <a href="mailto:hello@barecollective.com">hello@barecollective.com</a></p><p>Aktuellt: <a href="https://example.com" target="_blank" rel="noreferrer" >Ny vd på plats</a></p>

        </div>
        <footer>
          <p>Våra varumärken</p>
          <figure>
            <a href="https://esska.nu"><img src={esska} alt="Esska" /></a>
          </figure>
          <figure>
            <a href="https://imsevimse.se/"><img src={imse} alt="Imse" /></a>
          </figure>
          <figure>
            <a href="https://imsevimse.se/"><img src={vimse} alt="Vimse" /></a>
          </figure>

        </footer>
      </section>
    </div>
  );
}

export default App;
